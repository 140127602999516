.privacy-policy {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .privacy-policy h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .privacy-policy h3 {
    font-size: 20px;
    margin-top: 20px;
  }
  
  .privacy-policy h4 {
    font-size: 18px;
    margin-top: 15px;
  }
  
  .privacy-policy p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .privacy-policy ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .privacy-policy address {
    font-style: normal;
  }
  
  .privacy-policy a {
    color: #0078d4;
    text-decoration: underline;
  }
  
  .privacy-policy a:hover {
    text-decoration: none;
  }
  