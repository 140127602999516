.facebook-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: none;
  color: white;
  background-color: #4285F4;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 0 1px #4285F4;
  margin-top: 20px;
  position: relative;
}

.facebook-icon {
  position: absolute;
  left: 10px;
}


.facebook-text {
  margin-left: 25px;
}

.facebook-button:hover {
  background-color: #3276b1;
}

.facebook-button:hover .facebook-text {
  color: rgb(51, 0, 255);
}

.facebook-button:hover .facebook-icon svg {
  color: rgb(0, 13, 255);
}

.btn-facebook {
  color: rgb(255, 255, 255);
  background-color: #4285F4;
  border-color: #4285F4;
  font-weight: 500;
}

.btn-facebook:hover {
  background-color: #3276b1;
  border-color: #285e8e;
}