.navbar-home {
    background-color: #88BA03;
}

.navbar-segPerimetral {
    background-color: white;
    border-bottom: 3px solid #2492EB;
}

h5 {
    font-size: 10px;
}

h1 {
  font-size: 10px;
}

.img-carrousel {
    border-radius: '10px';
}

