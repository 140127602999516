.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: none;
  color: white;
  background-color: #4285F4;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 0 1px #4285F4;
  margin-top: 20px;
  position: relative;
  margin-right: 20px;
}

.google-icon {
  position: absolute;
  left: 10px;
}


.google-text {
  margin-left: 25px;
}

.google-button:hover {
  background-color: #3276b1;
}

.google-button:hover .google-text {
  color: rgb(255, 0, 0);
}

.google-button:hover .google-icon svg {
  color: rgb(255, 0, 0);
}

.btn-google {
  color: rgb(255, 255, 255);
  background-color: #4285F4;
  border-color: #4285F4;
  font-weight: 500;
}

.btn-google:hover {
  background-color: #3276b1;
  border-color: #285e8e;
}