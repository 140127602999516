
.image-paragraph-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Cambia a centrar horizontal y verticalmente */
  width: 100%;
  padding: 20px; /* Puedes ajustar el espaciado según tus preferencias */
}

.image-container {
  flex: 1; /* Espaciado entre la imagen y el párrafo */
  display: flex;
  justify-content: center; /* Centra la imagen horizontalmente */
  align-items: center; /* Centra la imagen verticalmente */
}

.image {
  max-width: 60%;
  height: auto;
}

.text-container {
  flex: 1;
  text-align: center; /* Centra el texto horizontalmente */
  font-size: 22px;
  margin-left: 30px;
  margin-right: 30px
}

.about-service-container {
    text-align: center; /* Centra el texto horizontalmente */
    margin-top: 40px; /* Puedes ajustar el espacio superior según tus preferencias */
  }
  
  h1 {
    font-size: 20px; /* Puedes ajustar el tamaño de fuente según tus preferencias */
  }
  
  /* Tu estilo para image-container y text-container aquí */
  
  /* Estilos para pantallas con un ancho máximo de 670px o menos */

  @media (max-width: 1200px) {
    .text-container {
          font-size: 22px
        }
  }

@media (max-width: 850px) {
    .image-paragraph-container {
      flex-direction: column; /* Cambia a diseño de columna */
      padding: 20px;
    }
  
    .image-container,
    .text-container {
    margin: 30px;
      flex: none; /* Reinicia la flexibilidad para que no se estiren */
      width: 100%; /* Ocupa todo el ancho disponible */
      text-align: center; /* Centra contenido horizontalmente */
      font-size: 24px
    }
  
    .image {
      max-width: 70%; /* La imagen ocupa todo el ancho disponible */
      height: auto;
    }
  }
  